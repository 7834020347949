<script setup>
const seoTitle = "Datanomic - Home";
const seoDescription = "";

useHead({
  title: seoTitle,
  meta: [
    { name: "description", content: seoDescription },
    { name: "og:title", content: seoTitle },
    { name: "og:description", content: seoDescription },
    { name: "keywords", content: "Datanomic" },
  ],
});

const blogs = ref([]);

const getBlogsAll = async () => {
  blogs.value = await getBlogsBase(3, [], [9]);
};

onMounted(() => {
  getBlogsAll();
});
</script>

<template>
  <div class="home-page">
    <section class="rounded-[48px] relative">
      <div class="max-sm:hidden -mx-20 max-lg:-mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/banner/Converted.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>
      <div class="sm:hidden -mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/banner/Converted-1.png"
          :imgAttrs="{ class: 'w-full h-[450px]', alt: 'home-banner-mobile' }"
          class="w-full"
        />
      </div>

      <div
        class="absolute max-2xl:bottom-[15px] top-1/2 max-md:top-[90px] max-md:left-[16px] left-[80px] md:-translate-y-1/2"
      >
        <h1 class="text-[#FCFCFD] max-sm:text-white">Data Driven Origanization</h1>

        <p
          class="w-1/2 text-[16px] max-sm:text-[12px] max-sm:w-full font-[600] max-sm:text-white mt-2 max-sm:mt-4 text-[#F4F5F6]"
        >
          We aspire to be the partner of choice in your transformation into a data-driven organization and enable you to
          manage and monetize data through effective use of people, process and technology.
        </p>

        <div class="flex max-sm:flex-col items-center gap-5 mt-[44px]">
          <nuxt-link to="/" class="w-[209px] max-sm:w-full">
            <button
              class="rounded-[12px] max-md:w-full h-[56px] bg-[#FCFCFD] text-[#0066E6] border-e-0 border-none"
              aria-label="solution-miraway"
            >
              GET STARTED
            </button>
          </nuxt-link>

          <nuxt-link to="/" class="w-[209px] max-sm:w-full">
            <button
              class="rounded-[12px] max-md:w-full h-[56px] text-[#FCFCFD] border-solid border-white border-2"
              aria-label="solution-miraway"
            >
              LEARN MORE
            </button>
          </nuxt-link>
        </div>
      </div>
    </section>

    <section id="solutions" class="">
      <div class="flex max-sm:flex-col flex-wrap max-xl:gap-y-4">
        <div
          class="w-2/3 max-md:w-2/3 max-lg:w-2/3 max-xl:w-2/3 max-2xl:w-[700px] max-sm:w-full max-xl:ml-0 max-md:pt-0 max-sm:pt-10 max-sm:order-1 pt-32 max-lg:pt-0 max-xl:pt-0"
        >
          <h3 class="font-bold text-4xl">These services help make your work more efficient</h3>
          <div class="flex max-md:flex-col flex-wrap gap-x-[24px] max-xl:gap-y-4 mt-14 max-md:mt-5 max-lg:mt-5">
            <div class="flex-1 flex max-sm:flex-col">
              <div id="qr-bao-hanh" class="items-start gap-x-6 max-xl:mt-0">
                <nuxt-picture
                  loading="lazy"
                  src="/images/datanomic/Frame-133.png"
                  class="shrink-0"
                  width="48"
                  :imgAttrs="{ alt: 'icon' }"
                />

                <div>
                  <h3 class="text-xl font-bold">Data Management</h3>

                  <div class="mt-3 w-[270px] text-sm font-normal max-xl:w-full">
                    This is the most technology heavy part in the framework and needs a lot of involvement from IT
                    professionals.
                  </div>

                  <div class="mt-5">
                    <nuxt-link to="/" class="text-[#0066E6] font-bold text-base"> See more </nuxt-link>
                  </div>
                </div>
              </div>

              <div id="qr-bao-hanh" class="items-start gap-x-6 max-lg:mt-0 ml-48 max-xl:ml-0 max-2xl:ml-32">
                <nuxt-picture
                  loading="lazy"
                  src="/images/datanomic/Frame-135.png"
                  class="shrink-0"
                  width="48"
                  :imgAttrs="{ alt: 'icon' }"
                />

                <div>
                  <h3 class="text-xl font-bold">BI & Data Analytics</h3>

                  <div class="mt-3 w-[280px] text-sm font-normal max-xl:w-full">
                    Analytics is the most exciting part of the data-driven framework. And, the one with the most
                    potential as well. To com...
                  </div>

                  <div class="mt-5">
                    <nuxt-link to="/" class="text-[#0066E6] font-bold text-base"> See more </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-wrap max-sm:mb-[500px]">
          <div class="w-full">
            <div class="w-full">
              <nuxt-picture
                loading="lazy"
                src="/images/datanomic/fpd-1.png"
                width="full"
                :imgAttrs="{ class: 'w-full', alt: 'phone-tich-diem' }"
              />
            </div>
          </div>
        </div>

        <div
          class="flex max-sm:flex-col flex-wrap gap-x-[24px] max-xl:gap-y-4 max-sm:mt-5 max-md:mt-5 max-xl:mt-10 mt-16 max-lg:mt-5 order-3"
        >
          <div class="flex-1 flex max-sm:flex-col">
            <div id="qr-bao-hanh" class="items-start gap-x-6 max-lg:mt-4 max-sm:mt-0">
              <nuxt-picture
                loading="lazy"
                src="/images/datanomic/Frame-134.png"
                class="shrink-0"
                width="48"
                :imgAttrs="{ alt: 'icon' }"
              />

              <div>
                <h3 class="text-xl font-bold">Data Strategy</h3>

                <div class="mt-3 w-[310px] text-sm font-normal max-xl:w-full">
                  Many companies make the mistake of starting their transformations or simply making investments in
                  data/informatio...
                </div>

                <div class="mt-5">
                  <nuxt-link to="/" class="text-[#0066E6] font-bold text-base"> See more </nuxt-link>
                </div>
              </div>
            </div>

            <div id="qr-bao-hanh" class="items-start gap-x-6 max-lg:mt-4 max-sm:mt-7 ml-36 max-xl:ml-0 max-2xl:ml-10">
              <nuxt-picture
                loading="lazy"
                src="/images/datanomic/Frame-136.png"
                class="shrink-0"
                width="48"
                :imgAttrs="{ alt: 'icon' }"
              />

              <div>
                <h3 class="text-xl font-bold">Data Governance</h3>

                <div class="mt-3 w-[290px] text-sm font-normal max-xl:w-full">
                  Data needs to be governed so people, who consume it through reports, dashboards & analytics, etc. and
                  take...
                </div>

                <div class="mt-5">
                  <nuxt-link to="/" class="text-[#0066E6] font-bold text-base"> See more </nuxt-link>
                </div>
              </div>
            </div>

            <div id="qr-bao-hanh" class="items-start gap-x-6 max-lg:mt-4 max-sm:mt-7 ml-40 max-xl:ml-0 max-2xl:ml-14">
              <nuxt-picture
                loading="lazy"
                src="/images/datanomic/Frame-137.png"
                class="shrink-0"
                width="48"
                :imgAttrs="{ alt: 'icon' }"
              />

              <div>
                <h3 class="text-xl font-bold">Customer data platform</h3>

                <div class="mt-3 w-[310px] text-sm font-normal max-xl:w-full">
                  The platform aggregates customer data across all touchpoints (online and in-person) into a single
                  platform.
                </div>

                <div class="mt-5">
                  <nuxt-link to="/" class="text-[#0066E6] font-bold text-base"> See more </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-28 max-lg:mt-10">
      <div class="-mx-20 max-lg:-mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/Frame-155.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>
    </section>

    <section id="zalo-app" class="m-auto">
      <div class="flex max-lg:flex gap-[45px] max-xl:gap-5 max-md:flex-wrap max-lg:gap-y-4 justify-center">
        <div class="pb-[14px] w-full overflow-hidden">
          <div class="-ml-[80px] max-xl:ml-0 max-2xl:-ml-[250px]">
            <nuxt-picture
              loading="lazy"
              src="/images/datanomic/business.png"
              :imgAttrs="{ class: 'w-full', alt: 'business' }"
            />
          </div>
        </div>

        <div
          class="w-4/12 max-xl:w-4/5 max-2xl:w-full max-xl:-mt-12 max-lg:mt-0 max-xl:ml-0 max-md:ml-0 max-lg:ml-0 max-sm:w-full flex gap-[32px] flex-col max-md:pb-0 px-[30px] rounded-[30px]"
        >
          <div class="mt-[140px] max-lg:mt-5 max-2xl:mt-20">
            <h3 class="font-bold text-3xl">Our vision</h3>
            <div class="text-sm mt-5 w-[420px] max-xl:w-full max-2xl:w-full font-normal">
              We aspire to be the partner of choice in your transformation into a data-driven organization and enable
              you to manage and monetize data through effective use of people, process and technology
            </div>
          </div>
          <div class="text-left">
            <nuxt-link to="/" class="w-[209px] max-xl:w-full">
              <button
                class="rounded-[12px] max-md:w-full h-[56px] bg-[#0066E6] text-[#FCFCFD] border-solid border-white border-2"
                aria-label="solution-miraway"
              >
                See more
              </button>
            </nuxt-link>
          </div>
        </div>
      </div>

      <div class="flex gap-[45px] w-full max-md:flex-wrap max-lg:gap-y-4 max-lg:mt-6 max-lg:gap-0">
        <div class="w-5/12 max-lg:w-5/6 flex gap-[32px] flex-col pb-[27px] px-[30px] rounded-[30px] max-md:order-1">
          <div class="mt-[250px] max-md:mt-0 max-md:ml-0 max-lg:ml-0 ml-[50px] max-2xl:mt-40 max-lg:mt-12">
            <h3 class="font-bold text-4xl max-sm:w-full">Data management</h3>
            <div class="text-base mt-5 w-[500px] max-xl:w-full font-normal">
              Aim to be a leading provider of data strategy, data management, data governance & analytics solutions
            </div>
          </div>
        </div>

        <div class="max-lg:w-full pb-[14px] rounded-[30px]">
          <div class="mx-auto max-md:mr-0">
            <nuxt-picture
              loading="lazy"
              src="/images/datanomic/Frame-144.png"
              width="full"
              :imgAttrs="{ class: 'w-full', alt: 'phone-mini-zalo-app' }"
            />
          </div>
        </div>
      </div>

      <div class="flex gap-[45px] max-md:flex-wrap max-lg:gap-y-4 max-lg:mt-6 max-2xl:gap-10 max-lg:gap-20">
        <div class="max-lg:w-full pb-[14px] rounded-[30px]">
          <div class="mx-auto w-full">
            <nuxt-picture
              loading="lazy"
              src="/images/datanomic/hands.png"
              width="full"
              :imgAttrs="{ class: 'w-full', alt: 'phone-mini-zalo-app' }"
            />
          </div>
        </div>

        <div
          class="w-4/12 max-lg:w-5/6 max-2xl:w-2/3 max-xl:w-2/3 flex gap-[32px] flex-col max-md:pt-0 pt-[90px] max-2xl:pt-0 max-xl:pt-2 px-[30px] max-lg:px-0 rounded-[30px]"
        >
          <div class="mt-[140px] max-2xl:ml-0 max-lg:mt-5 max-md:mt-0 max-md:ml-0 ml-[50px] max-2xl:mt-20">
            <h3 class="font-bold text-3xl max-xl:w-full">Best professional solutions</h3>
            <div class="text-sm mt-5 w-[420px] max-xl:w-full max-2xl:w-full font-normal">
              Our motto "Your Trusted Partner In Everything Data" signifiles our commitment across all critical stages
              in monetizing data
            </div>
            <br />
            <h3 class="font-bold text-3xl w-[370px] max-2xl:w-full max-xl:w-full">Strong team & partners</h3>
            <ul class="mt-9 list-disc lg:w-1/2 max-xl:w-full">
              <li class="w-[450px] max-2xl:w-full max-xl:w-full">
                Strong team of experts working both onsite and offsite.
              </li>
              <li class="mt-2 w-[400px] max-2xl:w-full max-xl:w-full">
                Strong partners to strengthen our capabilities.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-[100px] max-lg:mt-10">
      <div class="-mx-20 max-lg:-mx-4 max-md:hidden">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/Frame-163.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>

      <div class="-mx-20 max-lg:-mx-4 max-md: hidden max-md:inline">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/Frame-164.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>
    </section>

    <section class="mt-[100px] max-lg:mt-10">
      <div class="-mx-20 max-lg:-mx-4 max-md:hidden">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/Frame-171.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>

      <div class="-mx-20 max-lg:-mx-4 max-md: hidden max-md:inline">
        <nuxt-picture
          loading="lazy"
          src="/images/datanomic/Group-16651.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>
    </section>

    <Contact class="mt-[80px] 2xl:container mx-auto" />
  </div>
</template>
